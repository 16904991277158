import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/GraphQLErrorList";
import CustomComponent from "../components/CustomComponent";
import componentMapper from "../components/componentMapper";

export const query = graphql`
  query compareQuery($key: String!) {
    page: sanityPage(slug: { current: { eq: "religion" } }) {
        ...PageInfo
    }
    compare: pagesCompare(key: { eq: $key }) {
      description
      feature_1_head
      feature_1_subhead
      feature_2_head
      feature_2_subhead
      feature_3_head
      feature_3_subhead
      feature_head
      headline_1
      headline_2
      headline_3
      key
      subhead_1
      subhead_2
      subhead_3
      title
      testimonial_head
    }
    settings: sanitySiteSettings {
      title
      id
      facebook
      instagram
      twitter
      _rawOpenGraph(resolveReferences: { maxDepth: 10 })
      _rawLogo(resolveReferences: { maxDepth: 10 })
    }
    footer: sanitySiteFooter {
      _rawContent(resolveReferences: { maxDepth: 10 })
      _rawLinks(resolveReferences: { maxDepth: 10 })
      _rawSlug(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Compare = ({ data: resp, errors, pageContext }) => {
  if (errors) {
    return (
      <GraphQLErrorList errors={errors} />
    );
  }

  const { page, compare: data, settings, footer } = resp;

  const { _rawContent } = page;

  const children = _rawContent?.map((block) => {
    if (block._type === "custom") {
      return <CustomComponent data={block} />;
    }
    switch (block.widget_id) {
      case 'feature_head': {
        block.heading.text = data.feature_head;
        break;
      }
      case 'feature_1': {
        block.heading.text = data.feature_1_head;
        block.subtitle = data.feature_1_subhead;
        break;
      }
      case 'feature_2': {
        block.heading.text = data.feature_2_head;
        block.subtitle = data.feature_2_subhead;
        break;
      }
      case 'feature_3': {
        block.heading.text = data.feature_3_head;
        block.subtitle = data.feature_3_subhead;
        break;
      }
      case 'headline_3': {
        block.heading.text = data.headline_3;
        block.subheading = data.subhead_3;
        break;
      }
      case 'testimonial_head': {
        block.heading.text = data.testimonial_head;
        break;
      }
    }
    const Component = componentMapper(block._type);
    return <Component key={block._key} data={block} />;
  });


  return (
    <Layout
      title={data.title}
      description={data.description}
      settings={settings}
      footer={footer}
    >
      <div className="row no-overflow shape-background shape-background-dots shape-background-right">
        <div className="col-xs-18 col-sm-16 col-md-14 col-md-offset-1 align-justify">
          <h1 dangerouslySetInnerHTML={{ __html: data.headline_1 }} />
          <h3 className="text-grey">{data.subhead_1}</h3>
        </div>
      </div>
      {children}
    </Layout>
  );
};

export default Compare;
